.centered-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh; /* This will center the button vertically */
  }
  
  .centered-button {
    color: black;
    border-radius: none;
  }
  
  .centered-button:hover {
    background-color: red;
  }
  @media (min-width: 600px) {
    .centered-button {
     display: none;
    }
  }
  
  @media (min-width: 960px) {
    .centered-button {
        display: none;
    }
  }
  