/* Hide Swiper on all screens by default */
.bannerSwiper {
  display: none;
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  .swiper {
    display: block;
    width: 100%;
    height: 100%;
  }

  .swiper-slide video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-device-width: 600px) {
  .swiper {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-device-width: 600px) {
  .bannerSwiper {
    margin-top: 167px;
    display: block;
  }

  .swiper {
    display: block;
    width: 100%;
    height: auto; /* Allow height to adjust based on content */
  }

  .swiper-slide video {
    display: block;
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
  }

  .swiper-slide {
    text-align: center;
    font-size: 14px; /* Smaller font size for smaller screens */
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
  }
}
