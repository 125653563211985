


.wrapper{
  display: flex;
  
}
.inputWrapper{
  width: 30%;
}


.newsletter_container {
  
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px;
  gap: 20px;
}

.newsletter_form {
  width: 75vw;
  margin-right: 15px;
  flex-direction: row-reverse;
  display: flex;
  gap: 15px;
  align-items: center;
}

.newsletter_heading {
  width: 38%;
  letter-spacing: 1px;
  color: #455c80;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Oblik', sans-serif;
  font-size: 24px;
}

.newsletter_form input[type="text"],
.newsletter_form input[type="email"] {
  width: 100%;
  height: 50px;
  padding: 10px;
  border: 1px solid #7c7777;
  font-size: 14px;
}

.newsletter_button {
  width: 100%;
  height: 50px;
  padding: 0 10px;
  background-color: #073762;
  color: white;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
}

@media only screen and (max-width: 860px) {
  .newsletter_heading  {
    width: 100%;
    font-size: 5vw;
  }

  .inputWrapper{
    width: 100%;
   
  }

  .wrapper{
    width: 80vw;
 
    flex-direction: column;
   
    
  }

  .newsletter_button{
    margin-top: 20px;
    width: 100%;
  }

  .newsletter_container {
    flex-direction: column;
    gap: 10px;
  }
  .newsletter_form {
    width: 100%;
    gap: 10px;
    flex-direction: column-reverse;
    margin-right: 0;
   
  }

  


}
