.BookingWrapper_container {
  display: none;
}

@media all and (min-width: 1024px) and (max-width: 1280px),
  all and (min-width: 768px) and (max-width: 1024px),
  all and (min-width: 480px) and (max-width: 768px),
  all and (max-width: 480px) {
  .BookingWrapper_container {
    gap: 15px;
    margin-top: 20px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .BookingWrapper_container > p > span {
    color: #073762;
    font-weight: 700;
  }
  .BookingWrapper_container > p {
    padding: 0 3px;
  }
  .ordial {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 300px;
    color: #ababab;
  }
  .ordial hr {
    width: 100%;
    border: 1px solid #dbdbdb;
  }
  .ordial p {
    padding-top: 4px;
    margin: 0;
    font-size: inherit;
    text-transform: uppercase;
  }
  .buttons {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    width: 220px;
    overflow: hidden;
  }
  .buttons button,
  .buttons a {
    color: black;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    border: 1px solid #04376f;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttons .inline {
    background: #04376f;
    color: white;
    outline: none;
  }
  .buttons .inline:hover {
    background: white;
    color: #04376f;
  }
  .buttons .outline {
    background: transparent;
    color: #04376f;
    outline: none;
  }
  .buttons .outline:hover {
    background: #04376f;
    color: white;
  }
  .buttons .inline:focus,
  .buttons .outline:focus {
    outline: none;
  }
}
